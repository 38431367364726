<template>
  <v-container fluid>
    <v-tabs slider-color="primary">
      <v-tab
        :key="tab"
        ripple
        :to="getSubpageRoute(tabKey)"
        v-for="(tab, tabKey) in tabs">
        {{ tab }}
      </v-tab>
    </v-tabs>
    <router-view class="pa-0" />
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tabs: {
        orderProvisionReport: 'MeinHilfsmittel',
        consultingAppointmentReport: 'MeinElektromobil',
      },
    }
  },
  methods: {
    getSubpageRoute(pageKey) {
      return {
        name: `report.${pageKey}`,
      }
    },
  },
}
</script>
